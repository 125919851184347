import Accordion from '~/components/Atoms/Organic/Accordion/Accordion.vue'
import AccordionItem from '~/components/Atoms/Organic/AccordionItem/AccordionItem'

export default {
  props: {
    titles: {
      type: Object,
      required: true,
    },

    components: {
      Accordion,
      AccordionItem,
    },

    items: {
      type: Array,
      required: true,
    },
  },
}
