import hotOrganicButton from '~/components/Atoms/Organic/Button/Button.vue'
import modalSignup from '~/mixins/modalSignup.js'

export default {
  mixins: [modalSignup],

  components: {
    hotOrganicButton,
    hotIcon: () => import('~/components/Molecules/Icon/Icon.vue'),
  },

  props: {
    title: {
      type: String,
      required: true,
    },

    body: {
      type: String,
      required: true,
    },

    openByDefault: {
      type: Boolean,
      default: false,
    },

    dataAttributes: {
      type: Object,
      default: () => {},
    },

    index: {
      type: Number,
      required: true,
    },

    cta: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isOpen: this.openByDefault,
    }
  },

  computed: {
    questionNumber() {
      return (this.index + 1).toString().padStart(2, '0')
    },
  },

  methods: {
    toggle() {
      this.isOpen = !this.isOpen
    },
  },
}
